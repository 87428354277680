import React from "react";
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";

import { useSoftUIController, setMiniSidenav } from "context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import SignIn from "pages/sign-in";
import MismatchedTransactionDetail from "pages/mismatched-transaction-detail";
import MerchantManagementDetail from "pages/merchant-management-detail";
import TransactionDetail from "pages/transaction-detail";
import TransactionEdit from "pages/transaction-edit";
import PspDetails from "pages/psp-details";
import BrandDetail from "pages/brand-detail";
import PspMidDetails from "pages/psp-mid-details";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import ChangePassword from "./pages/sign-in/ChangePassword";
import PspMethods from "./pages/psp-methods";
import { hasPermissions } from "utils/roles";
import ReconciliationExport from "pages/reconciliation-export";
import { getToken } from "utils/cache";
import PspFeeDetail from "pages/psp-fee-detail";
import OrganizationDetails from "pages/organization-details";

const queryClient = new QueryClient();
const ROOTDOMAIN = window.location.host.split(".").slice(-2).join(".");

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [accessRouts, setAccessRouts] = useState(routes);
  const { pathname } = useLocation();
  const logoImg = `https://images.${ROOTDOMAIN}/public/images/logo.svg`;

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BRAND}  dashboard`;
  }, [ROOTDOMAIN]);

  const newRoutes = routes?.filter((route) => {
    const token = getToken();
    if (
      !token &&
      window.location.pathname !== "/sign-in" &&
      window.location.pathname !== "/change-password" &&
      window.location.pathname !== "/forgot-password"
    ) {
      window.location.replace(`${window.location.origin}/sign-in`);
    }
    if (!route.permissionsRequired) {
      return true;
    }
    return hasPermissions(route.permissionsRequired);
  });

  useEffect(() => {
    setAccessRouts(newRoutes);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const getRoutes = (allRoutes) =>
    allRoutes?.map((route) => {
      if (route?.collapse) {
        return getRoutes(route?.collapse);
      }

      if (route?.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const customRoutes = () => {
    const token = getToken();
    return (
      <>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/mismatched-transactions/:id" element={<MismatchedTransactionDetail />} />
        <Route path="/merchants/:id" element={<MerchantManagementDetail />} />
        <Route path="/transactions/:id" element={<TransactionDetail />} />
        <Route path="/transactions/edit/:id" element={<TransactionEdit />} />
        <Route path="/psps/:id" element={<PspDetails />} />
        <Route path="/merchants/:id/brands/:brandId" element={<BrandDetail />} />
        <Route path="/psps/:id/mid/:midId" element={<PspMidDetails />} />
        <Route path="/reconciliation-exports" element={<ReconciliationExport />} />
        <Route path="/psps/:id/methods/:methodId" element={<PspMethods />} />
        <Route path="/psps/:id/fees/:feeId" element={<PspFeeDetail />} />
        <Route path="/organizations/:id" element={<OrganizationDetails />} />
        <Route path="*" element={<Navigate to={token ? "/merchants" : "/sign-in"} />} />
      </>
    );
  };

  return direction === "rtl" ? (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          <ToastContainer />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={logoImg}
                brandName="Admin Panel"
                routes={accessRouts}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>{getRoutes(accessRouts)}</Routes>
          {customRoutes()}
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={logoImg}
              brandName="Admin Panel"
              routes={accessRouts}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(accessRouts)}
          <Route path="/sign-in" element={<SignIn />} />
          {customRoutes()}
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
